// accounting
export const SET_REIMBURSEMENT_PROOF_APPROVE_PAGE = 'SET_REIMBURSEMENT_PROOF_APPROVE_PAGE';
export const SET_REIMBURSEMENT_PROOF_APPROVE_ROWS_PER_PAGE = 'SET_REIMBURSEMENT_PROOF_APPROVE_ROWS_PER_PAGE';
export const SET_REIMBURSEMENT_PROOF_APPROVE_QUERY = 'SET_REIMBURSEMENT_PROOF_APPROVE_QUERY';
export const SET_REIMBURSEMENT_PROOF_MINE_PAGE = 'SET_REIMBURSEMENT_PROOF_MINE_PAGE';
export const SET_REIMBURSEMENT_PROOF_MINE_ROWS_PER_PAGE = 'SET_REIMBURSEMENT_PROOF_MINE_ROWS_PER_PAGE';
export const SET_REIMBURSEMENT_PROOF_MINE_QUERY = 'SET_REIMBURSEMENT_PROOF_MINE_QUERY';

// admin
export const SET_GUARDIAN_EVENT_LIST_PAGE = 'SET_GUARDIAN_EVENT_LIST_PAGE';
export const SET_GUARDIAN_EVENT_LIST_ROWS_PER_PAGE = 'SET_GUARDIAN_EVENT_LIST_ROWS_PER_PAGE';
export const SET_GUARDIAN_EVENT_LIST_QUERY = 'SET_GUARDIAN_EVENT_LIST_QUERY';
export const SET_GUARDIAN_EVENT_RESULT_QUERY = 'SET_GUARDIAN_EVENT_RESULT_QUERY';
export const SET_GUARDIAN_EVENT_RESULT_NIANBAN = 'SET_GUARDIAN_EVENT_RESULT_NIANBAN';
export const SET_GUARDIAN_EVENT_RESULT_DEPT = 'SET_GUARDIAN_EVENT_RESULT_DEPT';
export const SET_GUARDIAN_EVENT_RESULT_MINS = 'SET_GUARDIAN_EVENT_RESULT_MINS';
export const SET_GUARDIAN_EVENT_EXPORT_PAGE = 'SET_GUARDIAN_EVENT_EXPORT_PAGE';
export const SET_GUARDIAN_EVENT_EXPORT_ROWS_PER_PAGE = 'SET_GUARDIAN_EVENT_EXPORT_ROWS_PER_PAGE';
export const SET_GUARDIAN_EVENT_EXPORT_QUERY = 'SET_GUARDIAN_EVENT_EXPORT_QUERY';

// admission
export const SET_ADM_SEM1_LIST_PAGE = 'SET_ADM_SEM1_LIST_PAGE';
export const SET_ADM_SEM1_LIST_ROWS_PER_PAGE = 'SET_ADM_SEM1_LIST_ROWS_PER_PAGE';
export const SET_ADM_SEM1_LIST_CLASS_YEAR = 'SET_ADM_SEM1_LIST_CLASS_YEAR';
export const SET_ADM_SEM1_LIST_QUERY = 'SET_ADM_SEM1_LIST_QUERY';
export const SET_ADM_SEM1_LIST_SEM1_TIME = 'SET_ADM_SEM1_LIST_SEM1_TIME';
export const SET_ADM_SEM1_LIST_CURR_ACAD_YEAR = 'SET_ADM_SEM1_LIST_CURR_ACAD_YEAR';
export const SET_ADM_SEM1_LIST_DEPT = 'SET_ADM_SEM1_LIST_DEPT';
export const SET_ADM_SEM1_LIST_ORDER_BY = 'SET_ADM_SEM1_LIST_ORDER_BY';

export const SET_ADM_LIST_PAGE = 'SET_ADM_LIST_PAGE';
export const SET_ADM_LIST_ROWS_PER_PAGE = 'SET_ADM_LIST_ROWS_PER_PAGE';
export const SET_ADM_LIST_CLASS_YEAR = 'SET_ADM_LIST_CLASS_YEAR';
export const SET_ADM_LIST_QUERY = 'SET_ADM_LIST_QUERY';
export const SET_ADM_LIST_SEM1_TIME = 'SET_ADM_LIST_SEM1_TIME';
export const SET_ADM_LIST_CURR_ACAD_YEAR = 'SET_ADM_LIST_CURR_ACAD_YEAR';
export const SET_ADM_LIST_DEPT = 'SET_ADM_LIST_DEPT';
export const SET_ADM_LIST_ORDER_BY = 'SET_ADM_LIST_ORDER_BY';

// experiment
export const SET_REIMBURSEMENT_PLAN_LIST_PAGE = 'SET_REIMBURSEMENT_PLAN_LIST_PAGE';
export const SET_REIMBURSEMENT_PLAN_LIST_ROWS_PER_PAGE = 'SET_REIMBURSEMENT_PLAN_LIST_ROWS_PER_PAGE';
export const SET_REIMBURSEMENT_PLAN_LIST_QUERY = 'SET_REIMBURSEMENT_PLAN_LIST_QUERY';

// houqin
export const SET_VENDOR_MAIN_QUERY_PAGE = 'SET_VENDOR_MAIN_QUERY_PAGE';
export const SET_VENDOR_MAIN_QUERY_ROWS_PER_PAGE = 'SET_VENDOR_MAIN_QUERY_ROWS_PER_PAGE';
export const SET_VENDOR_MAIN_QUERY_QUERY = 'SET_VENDOR_MAIN_QUERY_QUERY';
export const SET_VENDOR_MAIN_QUERY_ORDER_BY = 'SET_VENDOR_MAIN_QUERY_ORDER_BY';

export const SET_ASSET_MAIN_QUERY_PAGE = 'SET_ASSET_MAIN_QUERY_PAGE';
export const SET_ASSET_MAIN_QUERY_ROWS_PER_PAGE = 'SET_ASSET_MAIN_QUERY_ROWS_PER_PAGE';
export const SET_ASSET_MAIN_QUERY_TYPE = 'SET_ASSET_MAIN_QUERY_TYPE';
export const SET_ASSET_MAIN_QUERY_IS_SCRAP = 'SET_ASSET_MAIN_QUERY_IS_SCRAP';
export const SET_ASSET_MAIN_QUERY_ORDER_BY = 'SET_ASSET_MAIN_QUERY_ORDER_BY';
export const SET_ASSET_MAIN_QUERY_QUERY = 'SET_ASSET_MAIN_QUERY_QUERY';
export const SET_ASSET_MAIN_SCRAP_QUERY_PAGE = 'SET_ASSET_MAIN_SCRAP_QUERY_PAGE';
export const SET_ASSET_MAIN_SCRAP_QUERY_ROWS_PER_PAGE = 'SET_ASSET_MAIN_SCRAP_QUERY_ROWS_PER_PAGE';

export const SET_ASSET_OWNER_QUERY_PAGE = 'SET_ASSET_OWNER_QUERY_PAGE';
export const SET_ASSET_OWNER_QUERY_ROWS_PER_PAGE = 'SET_ASSET_OWNER_QUERY_ROWS_PER_PAGE';
export const SET_ASSET_OWNER_SCRAP_QUERY_PAGE = 'SET_ASSET_OWNER_SCRAP_QUERY_PAGE';
export const SET_ASSET_OWNER_SCRAP_QUERY_ROWS_PER_PAGE = 'SET_ASSET_OWNER_SCRAP_QUERY_ROWS_PER_PAGE';
export const SET_ASSET_OWNER_REASSIGN_QUERY_PAGE = 'SET_ASSET_OWNER_REASSIGN_QUERY_PAGE';
export const SET_ASSET_OWNER_REASSIGN_QUERY_ROWS_PER_PAGE = 'SET_ASSET_OWNER_REASSIGN_QUERY_ROWS_PER_PAGE';

export const SET_WEIXIU_MAIN_QUERY_PAGE = 'SET_WEIXIU_MAIN_QUERY_PAGE';
export const SET_WEIXIU_MAIN_QUERY_ROWS_PER_PAGE = 'SET_WEIXIU_MAIN_QUERY_ROWS_PER_PAGE';
export const SET_WEIXIU_MAIN_QUERY_QUERY = 'SET_WEIXIU_MAIN_QUERY_QUERY';
export const SET_WEIXIU_MAIN_QUERY_STATUS = 'SET_WEIXIU_MAIN_QUERY_STATUS';
export const SET_WEIXIU_MAIN_QUERY_ORDER_BY = 'SET_WEIXIU_MAIN_QUERY_ORDER_BY';

export const SET_WEIXIU_MAIN_MANAGER_QUERY_PAGE = 'SET_WEIXIU_MAIN_MANAGER_QUERY_PAGE';
export const SET_WEIXIU_MAIN_MANAGER_QUERY_ROWS_PER_PAGE = 'SET_WEIXIU_MAIN_MANAGER_QUERY_ROWS_PER_PAGE';
export const SET_WEIXIU_MAIN_MANAGER_QUERY_QUERY = 'SET_WEIXIU_MAIN_MANAGER_QUERY_QUERY';
export const SET_WEIXIU_MAIN_MANAGER_QUERY_ORDER_BY = 'SET_WEIXIU_MAIN_MANAGER_QUERY_ORDER_BY';

// hr
export const SET_ADMIN_EVENT_LIST_PAGE = 'SET_ADMIN_EVENT_LIST_PAGE';
export const SET_ADMIN_EVENT_LIST_ROWS_PER_PAGE = 'SET_ADMIN_EVENT_LIST_ROWS_PER_PAGE';
export const SET_ADMIN_EVENT_LIST_QUERY = 'SET_ADMIN_EVENT_LIST_QUERY';
export const SET_ADMIN_EVENT_LIST_ORDER_BY = 'SET_ADMIN_EVENT_LIST_ORDER_BY';

// registration
export const SET_STUDENT_LIST_QUERY_PAGE = 'SET_STUDENT_LIST_QUERY_PAGE';
export const SET_STUDENT_LIST_QUERY_ROWS_PER_PAGE = 'SET_STUDENT_LIST_QUERY_ROWS_PER_PAGE';
export const SET_STUDENT_LIST_QUERY_NAME = 'SET_STUDENT_LIST_QUERY_NAME';
export const SET_STUDENT_LIST_QUERY_NIANBAN = 'SET_STUDENT_LIST_QUERY_NIANBAN';
export const SET_STUDENT_LIST_QUERY_DEPT = 'SET_STUDENT_LIST_QUERY_DEPT';
export const SET_STUDENT_LIST_QUERY_ORDER_BY = 'SET_STUDENT_LIST_QUERY_ORDER_BY';

export const SET_REG_STUDENT_LIST_GUARDIAN_CARD_QUERY_PAGE = 'SET_REG_STUDENT_LIST_GUARDIAN_CARD_QUERY_PAGE';
export const SET_REG_STUDENT_LIST_GUARDIAN_CARD_QUERY_ROWS_PER_PAGE = 'SET_REG_STUDENT_LIST_GUARDIAN_CARD_QUERY_ROWS_PER_PAGE';
export const SET_REG_STUDENT_LIST_GUARDIAN_CARD_QUERY_NAME = 'SET_REG_STUDENT_LIST_GUARDIAN_CARD_QUERY_NAME';
export const SET_REG_STUDENT_LIST_GUARDIAN_CARD_QUERY_NIANBAN = 'SET_REG_STUDENT_LIST_GUARDIAN_CARD_QUERY_NIANBAN';
export const SET_REG_STUDENT_LIST_GUARDIAN_CARD_QUERY_DEPT = 'SET_REG_STUDENT_LIST_GUARDIAN_CARD_QUERY_DEPT';
export const SET_REG_STUDENT_LIST_GUARDIAN_CARD_QUERY_ORDER_BY = 'SET_REG_STUDENT_LIST_GUARDIAN_CARD_QUERY_ORDER_BY';

export const SET_PROSP_STUDENT_LIST_QUERY_PAGE = 'SET_PROSP_STUDENT_LIST_QUERY_PAGE';
export const SET_PROSP_STUDENT_LIST_QUERY_ROWS_PER_PAGE = 'SET_PROSP_STUDENT_LIST_QUERY_ROWS_PER_PAGE';
export const SET_PROSP_STUDENT_LIST_QUERY = 'SET_PROSP_STUDENT_LIST_QUERY';
export const SET_PROSP_STUDENT_LIST_QUERY_CLASS_YEAR = 'SET_PROSP_STUDENT_LIST_QUERY_CLASS_YEAR';
export const SET_PROSP_STUDENT_LIST_QUERY_DEPT = 'SET_PROSP_STUDENT_LIST_QUERY_DEPT';
export const SET_PROSP_STUDENT_LIST_QUERY_PAID_STATUS = 'SET_PROSP_STUDENT_LIST_QUERY_PAID_STATUS';
export const SET_PROSP_STUDENT_LIST_QUERY_ORDER_BY = 'SET_PROSP_STUDENT_LIST_QUERY_ORDER_BY';

// staff
export const SET_STAFF_LIST_PAGE = 'SET_STAFF_LIST_PAGE';
export const SET_STAFF_LIST_ROWS_PER_PAGE = 'SET_STAFF_LIST_ROWS_PER_PAGE';
export const SET_STAFF_LIST_QUERY = 'SET_STAFF_LIST_QUERY';
export const SET_STAFF_LIST_ORDER_BY = 'SET_STAFF_LIST_ORDER_BY';

// kpce
export const SET_KPCE_STUDENT_LIST_QUERY_PAGE = 'SET_KPCE_STUDENT_LIST_QUERY_PAGE';
export const SET_KPCE_STUDENT_LIST_QUERY_ROWS_PER_PAGE = 'SET_KPCE_STUDENT_LIST_QUERY_ROWS_PER_PAGE';
export const SET_KPCE_STUDENT_LIST_QUERY_QUERY = 'SET_KPCE_STUDENT_LIST_QUERY_QUERY';
export const SET_KPCE_STUDENT_LIST_QUERY_ORDER_BY = 'SET_KPCE_STUDENT_LIST_QUERY_ORDER_BY';
export const SET_KPCE_COURSE_LIST_QUERY_PAGE = 'SET_KPCE_COURSE_LIST_QUERY_PAGE';
export const SET_KPCE_COURSE_LIST_QUERY_ROWS_PER_PAGE = 'SET_KPCE_COURSE_LIST_QUERY_ROWS_PER_PAGE';
export const SET_KPCE_COURSE_LIST_QUERY_QUERY = 'SET_KPCE_COURSE_LIST_QUERY_QUERY';
export const SET_KPCE_COURSE_LIST_QUERY_ORDER_BY = 'SET_KPCE_COURSE_LIST_QUERY_ORDER_BY';
export const SET_KPCE_REGISTRATION_LIST_QUERY_PAGE = 'SET_KPCE_REGISTRATION_LIST_QUERY_PAGE';
export const SET_KPCE_REGISTRATION_LIST_QUERY_ROWS_PER_PAGE = 'SET_KPCE_REGISTRATION_LIST_QUERY_ROWS_PER_PAGE';
export const SET_KPCE_REGISTRATION_LIST_QUERY_QUERY = 'SET_KPCE_REGISTRATION_LIST_QUERY_QUERY';
export const SET_KPCE_REGISTRATION_LIST_QUERY_ORDER_BY = 'SET_KPCE_REGISTRATION_LIST_QUERY_ORDER_BY';
export const SET_KPCE_TEACHER_LIST_QUERY_PAGE = 'SET_KPCE_TEACHER_LIST_QUERY_PAGE';
export const SET_KPCE_TEACHER_LIST_QUERY_ROWS_PER_PAGE = 'SET_KPCE_TEACHER_LIST_QUERY_ROWS_PER_PAGE';
export const SET_KPCE_TEACHER_LIST_QUERY_QUERY = 'SET_KPCE_TEACHER_LIST_QUERY_QUERY';
export const SET_KPCE_TEACHER_LIST_QUERY_ORDER_BY = 'SET_KPCE_TEACHER_LIST_QUERY_ORDER_BY';
export const SET_KPCE_FEE_LIST_QUERY_PAGE = 'SET_KPCE_FEE_LIST_QUERY_PAGE';
export const SET_KPCE_FEE_LIST_QUERY_ROWS_PER_PAGE = 'SET_KPCE_FEE_LIST_QUERY_ROWS_PER_PAGE';
export const SET_KPCE_FEE_LIST_QUERY_QUERY = 'SET_KPCE_FEE_LIST_QUERY_QUERY';
export const SET_KPCE_FEE_LIST_QUERY_ORDER_BY = 'SET_KPCE_FEE_LIST_QUERY_ORDER_BY';
