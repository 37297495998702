import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../helpers/base';

const initialState = {
    student_list_query_page: 0,
    student_list_query_rows_per_page: 20,
    student_list_query_name: '',
    student_list_query_nianban: [],
    student_list_query_dept: [],
    student_list_query_order_by: 'id_school ASC',
    student_list_guardian_card_query_page: 0,
    student_list_guardian_card_query_rows_per_page: 20,
    student_list_guardian_card_query_name: '',
    student_list_guardian_card_query_nianban: [],
    student_list_guardian_card_query_dept: [],
    student_list_guardian_card_query_order_by: 'id_school ASC',
    prosp_student_list_query_page: 0,
    prosp_student_list_query_rows_per_page: 300,
    prosp_student_list_query: '',
    prosp_student_list_query_class_year: ' ',
    prosp_student_list_query_dept: ' ',
    prosp_student_list_query_paid_status: 2,
    prosp_student_list_query_order_by: 'name_full_zh asc',
};

const set_prosp_student_list_query_page = (state, action) => {
    return updateObject(state, {
        prosp_student_list_query_page: action.data,
    });
};

const set_prosp_student_list_query_rows_per_page = (state, action) => {
    return updateObject(state, {
        prosp_student_list_query_rows_per_page: action.data,
    });
};

const set_prosp_student_list_query = (state, action) => {
    return updateObject(state, {
        prosp_student_list_query: action.data,
    });
};

const set_prosp_student_list_query_class_year = (state, action) => {
    return updateObject(state, {
        prosp_student_list_query_class_year: action.data,
    });
};

const set_prosp_student_list_query_dept = (state, action) => {
    return updateObject(state, {
        prosp_student_list_query_dept: action.data,
    });
};

const set_prosp_student_list_query_paid_status = (state, action) => {
    return updateObject(state, {
        prosp_student_list_query_paid_status: action.data,
    });
};

const set_prosp_student_list_query_order_by = (state, action) => {
    return updateObject(state, {
        prosp_student_list_query_order_by: action.data,
    });
};

const set_student_list_query_page = (state, action) => {
    return updateObject(state, {
        student_list_query_page: action.data,
    });
};

const set_student_list_query_rows_per_page = (state, action) => {
    return updateObject(state, {
        student_list_query_rows_per_page: action.data,
    });
};

const set_student_list_query_name = (state, action) => {
    return updateObject(state, {
        student_list_query_name: action.data,
    });
};

const set_student_list_query_nianban = (state, action) => {
    return updateObject(state, {
        student_list_query_nianban: action.data,
    });
};

const set_student_list_query_dept = (state, action) => {
    return updateObject(state, {
        student_list_query_dept: action.data,
    });
};

const set_student_list_query_order_by = (state, action) => {
    return updateObject(state, {
        student_list_query_order_by: action.data,
    });
};

const set_student_list_guardian_card_query_page = (state, action) => {
    return updateObject(state, {
        student_list_guardian_card_query_page: action.data,
    });
};

const set_student_list_guardian_card_query_rows_per_page = (state, action) => {
    return updateObject(state, {
        student_list_guardian_card_query_rows_per_page: action.data,
    });
};

const set_student_list_guardian_card_query_name = (state, action) => {
    return updateObject(state, {
        student_list_guardian_card_query_name: action.data,
    });
};

const set_student_list_guardian_card_query_nianban = (state, action) => {
    return updateObject(state, {
        student_list_guardian_card_query_nianban: action.data,
    });
};

const set_student_list_guardian_card_query_dept = (state, action) => {
    return updateObject(state, {
        student_list_guardian_card_query_dept: action.data,
    });
};

const set_student_list_guardian_card_query_order_by = (state, action) => {
    return updateObject(state, {
        student_list_guardian_card_query_order_by: action.data,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_STUDENT_LIST_QUERY_PAGE: return set_student_list_query_page(state, action);
        case actionTypes.SET_STUDENT_LIST_QUERY_ROWS_PER_PAGE: return set_student_list_query_rows_per_page(state, action);
        case actionTypes.SET_STUDENT_LIST_QUERY_NAME: return set_student_list_query_name(state, action);
        case actionTypes.SET_STUDENT_LIST_QUERY_NIANBAN: return set_student_list_query_nianban(state, action);
        case actionTypes.SET_STUDENT_LIST_QUERY_DEPT: return set_student_list_query_dept(state, action);
        case actionTypes.SET_STUDENT_LIST_QUERY_ORDER_BY: return set_student_list_query_order_by(state, action);

        case actionTypes.SET_REG_STUDENT_LIST_GUARDIAN_CARD_QUERY_PAGE: return set_student_list_guardian_card_query_page(state, action);
        case actionTypes.SET_REG_STUDENT_LIST_GUARDIAN_CARD_QUERY_ROWS_PER_PAGE: return set_student_list_guardian_card_query_rows_per_page(state, action);
        case actionTypes.SET_REG_STUDENT_LIST_GUARDIAN_CARD_QUERY_NAME: return set_student_list_guardian_card_query_name(state, action);
        case actionTypes.SET_REG_STUDENT_LIST_GUARDIAN_CARD_QUERY_NIANBAN: return set_student_list_guardian_card_query_nianban(state, action);
        case actionTypes.SET_REG_STUDENT_LIST_GUARDIAN_CARD_QUERY_DEPT: return set_student_list_guardian_card_query_dept(state, action);
        case actionTypes.SET_REG_STUDENT_LIST_GUARDIAN_CARD_QUERY_ORDER_BY: return set_student_list_guardian_card_query_order_by(state, action);

        case actionTypes.SET_PROSP_STUDENT_LIST_QUERY_PAGE: return set_prosp_student_list_query_page(state, action);
        case actionTypes.SET_PROSP_STUDENT_LIST_QUERY_ROWS_PER_PAGE: return set_prosp_student_list_query_rows_per_page(state, action);
        case actionTypes.SET_PROSP_STUDENT_LIST_QUERY: return set_prosp_student_list_query(state, action);
        case actionTypes.SET_PROSP_STUDENT_LIST_QUERY_CLASS_YEAR: return set_prosp_student_list_query_class_year(state, action);
        case actionTypes.SET_PROSP_STUDENT_LIST_QUERY_DEPT: return set_prosp_student_list_query_dept(state, action);
        case actionTypes.SET_PROSP_STUDENT_LIST_QUERY_PAID_STATUS: return set_prosp_student_list_query_paid_status(state, action);
        case actionTypes.SET_PROSP_STUDENT_LIST_QUERY_ORDER_BY: return set_prosp_student_list_query_order_by(state, action);
        default: return state;
    };
};

export default reducer;