import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../helpers/base';

const initialState = {
    admin_event_list_page: 0,
    admin_event_list_rows_per_page: 20,
    admin_event_list_query: ' ',
    admin_event_list_order_by: 'quarter DESC ',
};

const set_admin_event_list_page = (state, action) => {
    return updateObject(state, {
        admin_event_list_page: action.data,
    });
};

const set_admin_event_list_rows_per_page = (state, action) => {
    return updateObject(state, {
        admin_event_list_rows_per_page: action.data,
    });
};

const set_admin_event_list_query = (state, action) => {
    return updateObject(state, {
        admin_event_list_query: action.data,
    });
};

const set_admin_event_list_order_by = (state, action) => {
    return updateObject(state, {
        admin_event_list_order_by: action.data,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ADMIN_EVENT_LIST_PAGE: return set_admin_event_list_page(state, action);
        case actionTypes.SET_ADMIN_EVENT_LIST_ROWS_PER_PAGE: return set_admin_event_list_rows_per_page(state, action);
        case actionTypes.SET_ADMIN_EVENT_LIST_QUERY: return set_admin_event_list_query(state, action);
        case actionTypes.SET_ADMIN_EVENT_LIST_ORDER_BY: return set_admin_event_list_order_by(state, action);

        default: return state;
    };
};

export default reducer;