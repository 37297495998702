import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

import Backdrop from '../components/UI/Backdrop';
import { urlBaseImgStatic } from '../helpers/url';

const Sidebar = (props) => {
    let unique_key = 0;
    let history = useHistory();
    let token_decoded = localStorage.getItem('token') ? jwtDecode(localStorage.getItem('token')) : null;

    const handle_click_item = (name) => {
        props.on_click_backdrop();
        switch (name) {
            case 'home':
                history.push('/');
            break;

            case 'registration_student_list':
                history.push('/registration_student_list');
            break;
            case 'registration_student_add':
                window.location.href = '/registration_student_add';
                // history.push('/registration_student_add');
            break;
            case 'registration_student_banji_list':
                // window.location.href = '/registration_student_banji_list';
                history.push('/registration_student_banji_list');
            break;
            case 'registration_student_sms_fill_info':
                history.push('/registration_student_sms_fill_info');
            break;
            case 'registration_student_export':
                history.push('/registration_student_export');
            break;
            case 'registration_student_print':
                history.push('/registration_student_print');
            break;
            case 'registration_import_prosp_student':
                history.push('/registration_import_prosp_student');
            break;
            case 'registration_prosp_student_list':
                history.push('/registration_prosp_student_list');
            break;


            case 'service_general_msg_delivery':
                history.push('/service_general_msg_delivery');
            break;
            case 'service_general_doc_apply':
                history.push('/service_general_doc_apply');
            break;


            case 'kpce_admin':
                history.push('/kpce_admin');
            break;
            case 'kpce_touke_import':
                history.push('/kpce_touke_import');
            break;


            case 'admission_prosp_student_list':
                history.push('/admission_prosp_student_list');
            break;
            case 'admission_prosp_student_add':
                window.location.href = '/admission_prosp_student_add';
                // history.push('/admission_prosp_student_add');
            break;
            case 'admission_prosp_student_import':
                history.push('/admission_prosp_student_import');
            break;
            case 'admission_prosp_student_sem1':
                history.push('/admission_prosp_student_sem1');
            break;
            case 'admission_export':
                history.push('/admission_export');
            break;


            case 'accounting_reimbursement':
                history.push('/accounting_reimbursement');
            break;


            case 'staff_list':
                history.push('/staff_list');
            break;
            case 'staff_add':
                window.location.href = '/staff_add';
                // history.push('/staff_add');
            break;
            case 'asset_mgmt':
                window.location.href = '/asset_mgmt';
            break;
            case 'asset_owner':
                window.location.href = '/asset_owner';
            break;
            case 'weixiu_admin':
                window.location.href = '/weixiu_admin';
            break;
            case 'vendor_mgmt':
                window.location.href = '/vendor_mgmt';
            break;

            
            case 'sms_list':
                history.push('/sms_list');
            break;
            case 'sms_free':
                history.push('/sms_free');
            break;


            case 'other_hsiabei':
                history.push('/hsiabei');
            break;
            case 'other_hsiabei_admin':
                history.push('/hsiabei_admin');
            break;
            case 'other_hsiabei_checker':
                history.push('/hsiabei_checker');
            break;


            case 'hr_zihuping_admin':
                history.push('/zihuping_admin');
            break;
            case 'hr_zihuping_survey':
                history.push('/zihuping_survey');
            break;


            case 'houqin_weixiu':
                history.push('/weixiu');
            break;

            
            case 'admin_data_manage':
                history.push('/admin_data_manage');
            break;


            case 'student_zhiping':
                history.push('/student_zhiping');
            break;


            case 'admin_data_manage':
                history.push('/admin_data_manage');
            break;
            case 'admin_guardian_event_manage':
                history.push('/admin_guardian_event_manage');
            break;


            case 'experiment_reimbursement_plan':
                history.push('/experiment_reimbursement_plan');
            break;


            case 'student_zhiping':
                history.push('/student_zhiping');
            break;
            case 'student_guardian_event_result':
                history.push('/student_guardian_event_result');
            break;
            case 'student_barcode':
                history.push('/student_barcode');
            break;
            case 'student_scholarship_apply':
                history.push('/student_scholarship_apply');
            break;


            case 'profile':
                window.location.href = '/profile';
                // history.push('/profile');
            break;
            case 'logout':
                history.push('/logout');
            break;
            case 'backdrop':
            default:
        }
    };

    // ******************************
    // accounting items
    let accounting_items = [];
    if (token_decoded && token_decoded.is_staff) {
            accounting_items.push(
            <SidebarMenuItemMobileHide key={ unique_key++ } onClick={() => { handle_click_item('accounting_reimbursement') }}>
                <SidebarmenuItemLabel style={{ paddingLeft: '2rem' }}>請款憑證</SidebarmenuItemLabel>
            </SidebarMenuItemMobileHide>
        );
    }
    if (accounting_items.length > 0) {
        accounting_items.unshift(
            <React.Fragment key={ unique_key++ }>
            <hr style={{ width: '100%', margin: '0.1rem auto', border: '1px solid #333333' }} />
            <SidebarMenuItemNoClick>
                <SidebarmenuItemLabel>行政中心 - 會計</SidebarmenuItemLabel>
            </SidebarMenuItemNoClick>
            </React.Fragment>
        );
    }

    // ******************************
    // admin items
    let admin_items = [];
    if (token_decoded && token_decoded.can_admin_guardian_event_manage) {
        admin_items.push(
            <SidebarMenuItem key={ unique_key++ } onClick={() => { handle_click_item('admin_guardian_event_manage') }}>
                <SidebarmenuItemLabel style={{ paddingLeft: '2rem' }}>家長活動管理</SidebarmenuItemLabel>
            </SidebarMenuItem>
        );
    }
    if (token_decoded && token_decoded.can_admin_data_manage) {
        admin_items.push(
            <SidebarMenuItemMobileHide key={ unique_key++ } onClick={() => { handle_click_item('admin_data_manage') }}>
                <SidebarmenuItemLabel style={{ paddingLeft: '2rem' }}>資料管理</SidebarmenuItemLabel>
            </SidebarMenuItemMobileHide>
        );
    }
    if (admin_items.length > 0) {
        admin_items.unshift(
            <React.Fragment key={ unique_key++ }>
            <hr style={{ width: '100%', margin: '0.1rem auto', border:'1px solid #333333', opacity:1 }} />
            <SidebarMenuItemNoClick>
                <SidebarmenuItemLabel>聯服中心 - 行政</SidebarmenuItemLabel>
            </SidebarMenuItemNoClick>
            </React.Fragment>
        );
    }

    // ******************************
    // experiment items
    let experiment_items = [];
    if (token_decoded && token_decoded.can_experiment_reimbursement_plan) {
        experiment_items.push(
            <SidebarMenuItemMobileHide key={ unique_key++ } onClick={() => { handle_click_item('experiment_reimbursement_plan') }}>
                <SidebarmenuItemLabel style={{ paddingLeft: '2rem' }}>補助計畫管理</SidebarmenuItemLabel>
            </SidebarMenuItemMobileHide>
        );
    }
    if (experiment_items.length > 0) {
        experiment_items.unshift(
            <React.Fragment key={ unique_key++ }>
            <hr style={{ width: '100%', margin: '0.1rem auto', border: '1px solid #333333' }} />
            <SidebarMenuItemNoClick>
                <SidebarmenuItemLabel>行政中心 - 實研組</SidebarmenuItemLabel>
            </SidebarMenuItemNoClick>
            </React.Fragment>
        );
    }

    // ******************************
    // houqin items
    let houqin_items = [];
    if (token_decoded && token_decoded.can_houqin_view_staff) {
        houqin_items.push(
            <SidebarMenuItemMobileHide key={ unique_key++ } onClick={() => { handle_click_item('staff_list') }}>
                <SidebarmenuItemLabel style={{ paddingLeft: '2rem' }}>檢視老師</SidebarmenuItemLabel>
            </SidebarMenuItemMobileHide>
        );
    }
    if (token_decoded && token_decoded.can_houqin_add_staff) {
        houqin_items.push(
            <SidebarMenuItemMobileHide key={ unique_key++ } onClick={() => { handle_click_item('staff_add') }}>
                <SidebarmenuItemLabel style={{ paddingLeft: '2rem' }}>新增老師</SidebarmenuItemLabel>
            </SidebarMenuItemMobileHide>
        );
    }
    if (token_decoded && (token_decoded.can_houqin_asset || token_decoded.can_houqin_asset_manager)) {
        houqin_items.push(
            <SidebarMenuItem key={ unique_key++ } onClick={() => { handle_click_item('asset_mgmt') }}>
                <SidebarmenuItemLabel style={{ paddingLeft: '2rem' }}>資產管理</SidebarmenuItemLabel>
            </SidebarMenuItem>
        );
    }
    if (token_decoded && (token_decoded.can_houqin_weixiu || token_decoded.can_houqin_weixiu_manager)) {
        houqin_items.push(
            <SidebarMenuItem key={ unique_key++ } onClick={() => { handle_click_item('weixiu_admin') }}>
                <SidebarmenuItemLabel style={{ paddingLeft: '2rem' }}>資產維修管理</SidebarmenuItemLabel>
            </SidebarMenuItem>
        );
    }
    if (token_decoded && (token_decoded.can_houqin_vendor_view || token_decoded.can_houqin_vendor_edit)) {
        houqin_items.push(
            <SidebarMenuItem key={ unique_key++ } onClick={() => { handle_click_item('vendor_mgmt') }}>
                <SidebarmenuItemLabel style={{ paddingLeft: '2rem' }}>廠商管理</SidebarmenuItemLabel>
            </SidebarMenuItem>
        );
    }
    if (token_decoded && token_decoded.is_staff) {
        houqin_items.push(
            <SidebarMenuItem key={unique_key++} onClick={() => {handle_click_item('asset_owner')}}>
                <SidebarmenuItemLabel style={{paddingLeft:'2rem'}}>保管資產</SidebarmenuItemLabel>
            </SidebarMenuItem>
        );
    }
    if (token_decoded && token_decoded.is_staff) {
        houqin_items.push(
            <SidebarMenuItem key={unique_key++} onClick={() => {handle_click_item('houqin_weixiu')}}>
                <SidebarmenuItemLabel style={{paddingLeft:'2rem'}}>資產維修報送</SidebarmenuItemLabel>
            </SidebarMenuItem>
        );
    }
    if (houqin_items.length > 0) {
        houqin_items.unshift(
            <React.Fragment key={ unique_key++ }>
            <hr style={{ width: '100%', margin: '0.1rem auto', border:'1px solid #333333', opacity:1 }} />
            <SidebarMenuItemNoClick>
                <SidebarmenuItemLabel>行政中心 - 後勤</SidebarmenuItemLabel>
            </SidebarMenuItemNoClick>
            </React.Fragment>
        );
    }

    // ******************************
    // hr items
    let hr_items = [];
    if (token_decoded && token_decoded.can_other_hsiabei) {
        hr_items.push(
            <SidebarMenuItem key={unique_key++} onClick={() => {handle_click_item('other_hsiabei')}}>
                <SidebarmenuItemLabel style={{paddingLeft:'2rem'}}>蝦米網</SidebarmenuItemLabel>
            </SidebarMenuItem>
        );
    }
    if (token_decoded && token_decoded.can_other_hsiabei_checker) {
        hr_items.push(
            <SidebarMenuItem key={unique_key++} onClick={() => {handle_click_item('other_hsiabei_checker')}}>
                <SidebarmenuItemLabel style={{paddingLeft:'2rem'}}>蝦米網-審核</SidebarmenuItemLabel>
            </SidebarMenuItem>
        );
    }
    if (token_decoded && token_decoded.can_other_hsiabei_admin) {
        hr_items.push(
            <SidebarMenuItemMobileHide key={unique_key++} onClick={() => {handle_click_item('other_hsiabei_admin')}}>
                <SidebarmenuItemLabel style={{paddingLeft:'2rem'}}>蝦米網-設定</SidebarmenuItemLabel>
            </SidebarMenuItemMobileHide>
        );
    }
    if (props.is_live_hr_zhp_event) {
        hr_items.push(
            <SidebarMenuItem key={unique_key++} onClick={() => {handle_click_item('hr_zihuping_survey')}}>
                <SidebarmenuItemLabel style={{paddingLeft:'2rem'}}>自互評-評分問卷</SidebarmenuItemLabel>
            </SidebarMenuItem>
        );
    }
    if (token_decoded && token_decoded.can_hr_zihuping_admin) {
        hr_items.push(
            <SidebarMenuItemMobileHide key={unique_key++} onClick={() => {handle_click_item('hr_zihuping_admin')}}>
                <SidebarmenuItemLabel style={{paddingLeft:'2rem'}}>自互評-行政作業</SidebarmenuItemLabel>
            </SidebarMenuItemMobileHide>
        );
    }
    if (hr_items.length > 0) {
        hr_items.unshift(
            <React.Fragment key={unique_key++}>
            <hr style={{width:'100%', margin:'0.1rem auto', border:'1px solid #333333', opacity:1}} />
            <SidebarMenuItemNoClick key={unique_key++}>
                <SidebarmenuItemLabel>行政中心 - 人事室</SidebarmenuItemLabel>
            </SidebarMenuItemNoClick>
            </React.Fragment>
        );
    }

    // ******************************
    // registration items
    let registration_items = [];
    if (token_decoded && token_decoded.can_registration_view_student) {
        registration_items.push(
            <SidebarMenuItemMobileHide key={ unique_key++ } onClick={() => { handle_click_item('registration_student_list') }}>
                <SidebarmenuItemLabel style={{ paddingLeft: '2rem' }}>學生作業</SidebarmenuItemLabel>
            </SidebarMenuItemMobileHide>
        );
    }
    if (token_decoded && token_decoded.can_registration_banji_student) {
        registration_items.push(
            <SidebarMenuItemMobileHide key={ unique_key++ } onClick={() => { handle_click_item('registration_student_banji_list') }}>
                <SidebarmenuItemLabel style={{ paddingLeft: '2rem' }}>班級作業</SidebarmenuItemLabel>
            </SidebarMenuItemMobileHide>
        );
    }
    // if (token_decoded && token_decoded.can_registration_add_student) {
    //     registration_items.push(
    //         <SidebarMenuItem key={ unique_key++ } onClick={() => { handle_click_item('registration_student_add') }}>
    //             <SidebarmenuItemLabel style={{ paddingLeft: '2rem' }}>新增學生</SidebarmenuItemLabel>
    //         </SidebarMenuItem>
    //     );
    // }
    if (token_decoded && (token_decoded.can_registration_sms_prosp_student || token_decoded.can_registration_view_prosp_student || token_decoded.can_registration_edit_prosp_student)) {
        registration_items.push(
            <SidebarMenuItemMobileHide key={unique_key++} onClick={() => { handle_click_item('registration_prosp_student_list') }}>
                <SidebarmenuItemLabel style={{ paddingLeft: '2rem' }}>招生作業</SidebarmenuItemLabel>
            </SidebarMenuItemMobileHide>
        );
    }
    if (token_decoded) {
        let has_registration_export_function = false;
        let has_registration_print_function = false;
        for (let key in token_decoded) {
            if (key.indexOf('can_registration_export_') > -1 && token_decoded[key]) {
                has_registration_export_function = true;
            }
            if (key.indexOf('can_registration_print_') > -1 && token_decoded[key]) {
                has_registration_print_function = true;
            }
        }
        if (has_registration_export_function) {
            registration_items.push(
                <SidebarMenuItemMobileHide key={ unique_key++ } onClick={() => { handle_click_item('registration_student_export') }}>
                    <SidebarmenuItemLabel style={{ paddingLeft: '2rem' }}>資料匯出</SidebarmenuItemLabel>
                </SidebarMenuItemMobileHide>
            );
        }
        if (has_registration_print_function) {
            registration_items.push(
                <SidebarMenuItemMobileHide key={ unique_key++ } onClick={() => { handle_click_item('registration_student_print') }}>
                    <SidebarmenuItemLabel style={{ paddingLeft: '2rem' }}>資料列印</SidebarmenuItemLabel>
                </SidebarMenuItemMobileHide>
            );
        }
    }
    if (registration_items.length > 0) {
        registration_items.unshift(
            <React.Fragment key={ unique_key++ }>
            <hr style={{ width: '100%', margin: '0.1rem auto', border:'1px solid #333333', opacity:1 }} />
            <SidebarMenuItemNoClick key={ unique_key++ }>
                <SidebarmenuItemLabel>聯服中心 - 註冊</SidebarmenuItemLabel>
            </SidebarMenuItemNoClick>
            </React.Fragment>
        );
    }


    // ******************************
    // service_general items
    let service_general_items = [];
    if (token_decoded && token_decoded.can_service_general_msg_delivery) {
        service_general_items.push(
            <SidebarMenuItemMobileHide key={unique_key++} onClick={() => {handle_click_item('service_general_msg_delivery')}}>
                <SidebarmenuItemLabel style={{paddingLeft:'2rem'}}>訊息發送</SidebarmenuItemLabel>
            </SidebarMenuItemMobileHide>
        );
    }
    if (token_decoded && token_decoded.can_service_general_doc_apply) {
        service_general_items.push(
            <SidebarMenuItemMobileHide key={unique_key++} onClick={() => {handle_click_item('service_general_doc_apply')}}>
                <SidebarmenuItemLabel style={{paddingLeft:'2rem'}}>文件申請</SidebarmenuItemLabel>
            </SidebarMenuItemMobileHide>
        );
    }
    if (service_general_items.length > 0) {
        service_general_items.unshift(
            <React.Fragment key={unique_key++}>
            <hr style={{width:'100%', margin:'0.1rem auto', border:'1px solid #333333', opacity:1}} />
            <SidebarMenuItemNoClick key={unique_key++}>
                <SidebarmenuItemLabel>聯服中心 - 綜合服務</SidebarmenuItemLabel>
            </SidebarMenuItemNoClick>
            </React.Fragment>
        );
    }


    // ******************************
    // kpce items
    let kpce_items = [];
    if (token_decoded && (token_decoded.can_kpce_view_student || token_decoded.can_kpce_edit_student)) {
        kpce_items.push(
            <SidebarMenuItemMobileHide key={unique_key++} onClick={() => {handle_click_item('kpce_admin')}}>
                <SidebarmenuItemLabel style={{paddingLeft:'2rem'}}>行政作業</SidebarmenuItemLabel>
            </SidebarMenuItemMobileHide>
        );
    }
    if (token_decoded && (token_decoded.can_kpce_touke_import)) {
        kpce_items.push(
            <SidebarMenuItemMobileHide key={unique_key++} onClick={() => {handle_click_item('kpce_touke_import')}}>
                <SidebarmenuItemLabel style={{paddingLeft:'2rem'}}>投課匯入</SidebarmenuItemLabel>
            </SidebarMenuItemMobileHide>
        );
    }
    if (kpce_items.length > 0) {
        kpce_items.unshift(
            <React.Fragment key={unique_key++}>
            <hr style={{width:'100%', margin:'0.1rem auto', border:'1px solid #333333', opacity:1}} />
            <SidebarMenuItemNoClick key={unique_key++}>
                <SidebarmenuItemLabel>推廣教育部</SidebarmenuItemLabel>
            </SidebarMenuItemNoClick>
            </React.Fragment>
        );
    }

    
    // ******************************
    // admission items
    let admission_items = [];
    // if (token_decoded && token_decoded.can_admission_import_prosp_student) {
    //     admission_items.push(
    //         <SidebarMenuItemMobileHide key={unique_key++} onClick={() => {handle_click_item('admission_prosp_student_import')}}>
    //             <SidebarmenuItemLabel style={{ paddingLeft: '2rem' }}>建檔匯入</SidebarmenuItemLabel>
    //         </SidebarMenuItemMobileHide>
    //     );
    // }
    // note this next one is REGISTRATION_import_prosp_student
    // in class year 2021 this function was used by registration because at that time admission has not started using this system yet
    // if (token_decoded && token_decoded.can_registration_import_prosp_student) {
    //     registration_items.push(
    //         <SidebarMenuItemMobileHide key={unique_key++} onClick={() => { handle_click_item('registration_import_prosp_student') }}>
    //             <SidebarmenuItemLabel style={{ paddingLeft: '2rem' }}>招生建檔匯入</SidebarmenuItemLabel>
    //         </SidebarMenuItemMobileHide>
    //     );
    // }
    if (token_decoded && (token_decoded.can_admission_sms_sem1 || token_decoded.can_admission_edit_prosp_student || token_decoded.can_admission_view_prosp_student_score || token_decoded.can_admission_edit_prosp_student_score)) {
        admission_items.push(
            <SidebarMenuItemMobileHide key={unique_key++} onClick={() => {handle_click_item('admission_prosp_student_sem1')}}>
                <SidebarmenuItemLabel style={{ paddingLeft: '2rem' }}>說明會作業</SidebarmenuItemLabel>
            </SidebarMenuItemMobileHide>
        );
    }
    if (token_decoded && (token_decoded.can_admission_view_prosp_student || token_decoded.can_admission_edit_prosp_student)) {
        admission_items.push(
            <SidebarMenuItemMobileHide key={unique_key++} onClick={() => {handle_click_item('admission_prosp_student_list')}}>
                <SidebarmenuItemLabel style={{ paddingLeft: '2rem' }}>錄取學生作業</SidebarmenuItemLabel>
            </SidebarMenuItemMobileHide>
        );
    }
    if (token_decoded && token_decoded.can_admission_add_prosp_student) {
        admission_items.push(
            <SidebarMenuItem key={unique_key++} onClick={() => {handle_click_item('admission_prosp_student_add')}}>
                <SidebarmenuItemLabel style={{ paddingLeft: '2rem' }}>新增學生</SidebarmenuItemLabel>
            </SidebarMenuItem>
        );
    }
    if (token_decoded) {
        let has_admission_export_function = false;
        for (let key in token_decoded) {
            if (key.indexOf('can_admission_export_') > -1 && token_decoded[key]) {
                has_admission_export_function = true;
                break;
            }
        }
        if (has_admission_export_function) {
            admission_items.push(
                <SidebarMenuItemMobileHide key={ unique_key++ } onClick={() => { handle_click_item('admission_export') }}>
                    <SidebarmenuItemLabel style={{ paddingLeft: '2rem' }}>資料匯出</SidebarmenuItemLabel>
                </SidebarMenuItemMobileHide>
            );
        }        
    }
    if (admission_items.length > 0) {
        admission_items.unshift(
            <React.Fragment key={ unique_key++ }>
            <hr style={{ width: '100%', margin: '0.1rem auto', border:'1px solid #333333', opacity:1 }} />
            <SidebarMenuItemNoClick key={ unique_key++ }>
                <SidebarmenuItemLabel>理念推廣 - 招生</SidebarmenuItemLabel>
            </SidebarMenuItemNoClick>
            </React.Fragment>
        );
    }

    // ******************************
    // sms items
    let sms_items = [];
    if (token_decoded && token_decoded.can_sms_free) {
        sms_items.push(
            <SidebarMenuItem key={unique_key++} onClick={() => {handle_click_item('sms_list')}}>
                <SidebarmenuItemLabel style={{ paddingLeft: '2rem' }}>檢視</SidebarmenuItemLabel>
            </SidebarMenuItem>
        );
    }
    if (token_decoded && token_decoded.can_sms_free) {
        sms_items.push(
            <SidebarMenuItem key={unique_key++} onClick={() => {handle_click_item('sms_free')}}>
                <SidebarmenuItemLabel style={{ paddingLeft: '2rem' }}>發送</SidebarmenuItemLabel>
            </SidebarMenuItem>
        );
    }
    if (sms_items.length > 0) {
        sms_items.unshift(
            <React.Fragment key={ unique_key++ }>
            <hr style={{ width: '100%', margin: '0.1rem auto', border:'1px solid #333333', opacity:1 }} />
            <SidebarMenuItemNoClick key={ unique_key++ }>
                <SidebarmenuItemLabel>簡訊</SidebarmenuItemLabel>
            </SidebarMenuItemNoClick>
            </React.Fragment>
        );
    }

    // ******************************
    // student items
    let student_items = [];
    if (token_decoded && token_decoded.is_student) {
        student_items.push(
            <SidebarMenuItem key={unique_key++} onClick={() => {handle_click_item('student_zhiping')}}>
                <SidebarmenuItemLabel style={{paddingLeft:'2rem'}}>質評紀錄</SidebarmenuItemLabel>
            </SidebarMenuItem>
        );
        student_items.push(
            <SidebarMenuItem key={unique_key++} onClick={() => {handle_click_item('student_guardian_event_result')}}>
                <SidebarmenuItemLabel style={{paddingLeft:'2rem'}}>家長活動時數</SidebarmenuItemLabel>
            </SidebarMenuItem>
        );
        student_items.push(
            <SidebarMenuItem key={unique_key++} onClick={() => {handle_click_item('student_barcode')}}>
                <SidebarmenuItemLabel style={{paddingLeft:'2rem'}}>個人條碼</SidebarmenuItemLabel>
            </SidebarMenuItem>
        );
        student_items.push(
            <SidebarMenuItem key={unique_key++} onClick={() => {handle_click_item('student_scholarship_apply')}}>
                <SidebarmenuItemLabel style={{paddingLeft:'2rem'}}>獎學金申請</SidebarmenuItemLabel>
            </SidebarMenuItem>
        );
    }
    if (student_items.length > 0) {
        student_items.unshift(
            <React.Fragment key={unique_key++}>
            <hr style={{width:'100%', margin:'0.1rem auto', border:'1px solid #333333', opacity:1}} />
            {/* <SidebarMenuItemNoClick>
                <SidebarmenuItemLabel>學生</SidebarmenuItemLabel>
            </SidebarMenuItemNoClick> */}
            </React.Fragment>
        );
    }



    return (
        <React.Fragment>
        <Backdrop show={ props.open } clicked={() => handle_click_item('backdrop')} />
        <SidebarContainer open={ props.open }>
            <div style={{ margin: '1rem auto 0.25rem' }}>
                <img alt="Logo" src={ urlBaseImgStatic + "/logo/logo_trans_001-380x95.png" } width="180px" />
            </div>
            <SidebarMenu>
                <SidebarMenuItem onClick={() => { handle_click_item('home') }}>
                    <SidebarmenuItemLabel>首頁</SidebarmenuItemLabel>
                </SidebarMenuItem>
                {registration_items}
                {service_general_items}
                {admin_items}
                {admission_items}
                {hr_items}
                {houqin_items}
                {accounting_items}
                {experiment_items}
                {kpce_items}
                {sms_items}
                {student_items}
                <br />
                {token_decoded && token_decoded.is_staff
                ? (<SidebarMenuItem><SidebarmenuItemLabel onClick={() => {handle_click_item('profile')}}>個人設定</SidebarmenuItemLabel></SidebarMenuItem>)
                : null}
                <SidebarMenuItem><SidebarmenuItemLabel onClick={() => {handle_click_item('logout')}}>登出</SidebarmenuItemLabel></SidebarMenuItem>
            </SidebarMenu>
        </SidebarContainer>
        </React.Fragment>
    );
}

export default Sidebar;

const SidebarContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 280px;
    max-width: 70%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1302;
    box-sizing: border-box;
    background-color: var(--kpvsBrown);
    color: #FFFFFF;
    transition: transform 0.3s ease-out;
    transform: ${props => props.open ? 'translateX(0)' : 'translateX(-100%)'};
    overflow-y: auto;

    @media (min-width: 768px) {
        width: 25%;
    }
`;

const SidebarMenu = styled.ul`
    display: flex;
    align-items: left;
    flex-direction: column;
    list-style: none;
    width: 100%;
    padding: 0px 2rem;
    margin: 1rem 0;
    box-sizing: border-box;
`;

const SidebarMenuItemNoClick = styled.li`
    height: 2rem;
`;

const SidebarMenuItem = styled.li`
    height: 2rem;
    &:hover {
        background: rgba(255, 255, 255, 0.05);
        box-shadow: inset 3px 0 0 0 #FFFFFF;
        cursor: pointer;
    }
`;

const SidebarMenuItemMobileHide = styled.li`
    display: none;

    @media (min-width: 768px) {
        display: list-item;
        height: 2rem;
        &:hover {
            background: rgba(255, 255, 255, 0.05);
            box-shadow: inset 3px 0 0 0 #FFFFFF;
            cursor: pointer;
        }
    }
`;

const SidebarmenuItemLabel = styled.div`
    color: #FFFFFF;
    font-weight: 500;
    text-align: left;
    padding-left: 1rem;
    margin-top: 0.25rem;
`;
