import axios from 'axios';
import jwtDecode from 'jwt-decode';

import { urlHostRest, environment } from './url';

export const unique_key = () => {
    return Math.floor(Math.random() * 9999999);
};

export const generateBase64FromImage = imageFile => {
    const reader = new FileReader();
    const promise = new Promise((resolve, reject) => {
      reader.onload = e => resolve(e.target.result);
      reader.onerror = err => reject(err);
    });
  
    reader.readAsDataURL(imageFile);
    return promise;
};

export const add_months_to_str = (inputStr, numMonths) => {
    let time = new Date(inputStr);
    time.setMonth(time.getMonth() + Number(numMonths));

    return time.getFullYear() + "-" + ('0' + (time.getMonth() + 1)).slice(-2) + "-" + ('0' + time.getDate()).slice(-2) + " " + ('0' + time.getHours()).slice(-2) + ":" + ('0' + time.getMinutes()).slice(-2) + ":" + ('0' + time.getSeconds()).slice(-2);
};

export const add_months_to_time = (inputTime, numMonths) => {
    let time = inputTime;
    time.setMonth(time.getMonth() + Number(numMonths));

    return time;
};

export const add_days_to_time = (input_time, num_days) => {
    let time = input_time;
    time.setDate(time.getDate() + Number(num_days));

    return time;
};

export const convert_add_reg_db_to_display = (data, _country, _state, _zip_tw) => {
    let result = '';
    if (data.add_reg_country === 'ZZ') {
        result += data.add_reg_country_text;
    }
    else if (data.add_reg_country !== 'TW') {
        let filter = _country.filter(item => { return item.code_iso31661_a2 === data.add_reg_country; });
        if (filter.length > 0) {
            result += filter[0].name_zh;
        }
    }
    if (data.add_reg_country !== 'TW') {
        result += data.add_reg_state_text;
    }
    else {
        let filter = _state.filter(item => { return item.code_iso31662 === data.add_reg_state; });
        if (filter.length > 0) {
            result += filter[0].name_zh;
        }
    }
    if (data.add_reg_country !== "TW") {
        result += data.add_reg_city_text;
    }
    else {
        let filter = _zip_tw.filter(item => { return item.zip === data.add_reg_city; });
        if (filter.length > 0) {
            result += filter[0].name_zh;
        }
    }
    result += data.add_reg_street1;
    result += ' ' + data.add_reg_street2;
    return result;
};

export const convert_add_mail_db_to_display = (data, _country, _state, _zip_tw) => {
    let result = '';
    if (data.add_mail_country === 'ZZ') {
        result += data.add_mail_country_text;
    }
    else if (data.add_mail_country !== 'TW') {
        let filter = _country.filter(item => { return item.code_iso31661_a2 === data.add_mail_country; });
        if (filter.length > 0) {
            result += filter[0].name_zh;
        }
    }
    if (data.add_mail_country !== 'TW') {
        result += data.add_mail_state_text;
    }
    else {
        let filter = _state.filter(item => { return item.code_iso31662 === data.add_mail_state; });
        if (filter.length > 0) {
            result += filter[0].name_zh;
        }
    }
    if (data.add_mail_country !== "TW") {
        result += data.add_mail_city_text;
    }
    else {
        let filter = _zip_tw.filter(item => { return item.zip === data.add_mail_city; });
        if (filter.length > 0) {
            result += filter[0].name_zh;
        }
    }
    result += data.add_mail_street1;
    result += ' ' + data.add_mail_street2;
    return result;
};

export const convert_gender_natural_code_to_text = (code) => {
    switch (parseInt(code, 10)) {
        case 0:
            return '女';
        case 1:
            return '男';
        default:
            return '';
    }
};

export const convert_date_to_list = (input) => {
    let return_list = [];
    if (input) {
        return_list.push(input.getFullYear());
        return_list.push(("0" + (input.getMonth() + 1)).slice(-2));
        return_list.push(("0" + input.getDate()).slice(-2));
        return_list.push(("0" + input.getHours()).slice(-2));
        return_list.push(("0" + input.getMinutes()).slice(-2));
        return_list.push(("0" + input.getSeconds()).slice(-2));    
    }
    return return_list;
}

// input has to be a date
export const display_yyyy_mm_dd = (input) => {
    let return_list = [];
    if (input) {
        return_list.push(input.getFullYear());
        return_list.push(("0" + (input.getMonth() + 1)).slice(-2));
        return_list.push(("0" + input.getDate()).slice(-2));
        return_list.push(("0" + input.getHours()).slice(-2));
        return_list.push(("0" + input.getMinutes()).slice(-2));
        return_list.push(("0" + input.getSeconds()).slice(-2));    
    }
    return return_list[0] + '-' + return_list[1] + '-' + return_list[2];
}

// input has to be a date
export const display_yyyy_mm_dd_hh_mm = (input) => {
    let return_list = [];
    if (input) {
        return_list.push(input.getFullYear());
        return_list.push(("0" + (input.getMonth() + 1)).slice(-2));
        return_list.push(("0" + input.getDate()).slice(-2));
        return_list.push(("0" + input.getHours()).slice(-2));
        return_list.push(("0" + input.getMinutes()).slice(-2));
        return_list.push(("0" + input.getSeconds()).slice(-2));    
    }
    return return_list[0] + '-' + return_list[1] + '-' + return_list[2] + ' ' + return_list[3] + ':' + return_list[4];
}

// input has to be a date
export const display_yyyy_mm_dd_hh_mm_ss = (input) => {
    let return_list = [];
    if (input) {
        return_list.push(input.getFullYear());
        return_list.push(("0" + (input.getMonth() + 1)).slice(-2));
        return_list.push(("0" + input.getDate()).slice(-2));
        return_list.push(("0" + input.getHours()).slice(-2));
        return_list.push(("0" + input.getMinutes()).slice(-2));
        return_list.push(("0" + input.getSeconds()).slice(-2));    
    }
    return return_list[0] + '-' + return_list[1] + '-' + return_list[2] + ' ' + return_list[3] + ':' + return_list[4] + ':' + return_list[5];
}

export const convert_date_string_to_list = (date_string) => {
    // date_string needs to be in format yyyy-mm-dd hh:mm:ss
    try {
        let date_list = [];
        let parts = date_string.split(' ');
        let date = parts[0].split('-');
        for (let a in date) {
            date_list.push(Number(date[a]));
        }
        let time = parts[1].split(':');
        for (let a in time) {
            date_list.push(Number(time[a]));
        }
        return date_list;    
    }
    catch {
        return "BAD_DATE_FORMAT";
    }
};

export const check_user_id_national_correct = async (id_national, uuid) => {
    return axios_http('post', '/user/check_id_national_correct', true, {
        id_national: id_national,
        uuid: uuid,
    })
    .then((res) => {
        if (res && res.data && res.data.message === "YES") {
            return true;
        }
        return false;
    });
};

export const check_prosp_student_id_national_correct = async (id_national, uuid) => {
    return axios_http('post', '/prosp_student/check_id_national_correct', true, {
        id_national: id_national,
        uuid: uuid,
    })
    .then((res) => {
        if (res && res.data && res.data.message === "YES") {
            return true;
        }
        return false;
    });
};

export const check_user_id_national_exists = async (id_national, uuid) => {
    return axios_http('post', '/user/check_id_national_exists', true, {
        id_national: id_national,
        uuid: uuid,
    })
    .then((res) => {
        if (res && res.data && res.data.message === "NO") {
            return false;
        }
        return true;
    });
};

export const check_prosp_student_id_national_exists = async (id_national, uuid, id_admission) => {
    return axios_http('post', '/prosp_student/check_id_national_exists', true, {
        id_national: id_national,
        uuid: uuid,
        id_admission: id_admission,
    })
    .then((res) => {
        if (res && res.data && res.data.message === "NO") {
            return false;
        }
        return true;
    });
};

export const check_email_exists = async (email, uuid) => {
    return axios_http('post', '/user/check_email_exists', true, {
        email: email,
        uuid: uuid,
    })
    .then((res) => {
        if (res && res.data && res.data.message === "NO") {
            return false;
        }
        return true;
    });
};

export const check_username_exists = async (username, uuid) => {
    return axios_http('post', '/user/check_username_exists', true, {
        username: username,
        uuid: uuid,
    })
    .then((res) => {
        if (res && res.data && res.data.message === "NO") {
            return false;
        }
        return true;
    });
};

export const check_experiment_id_pingzheng_exists = async (id_pingzheng, id) => {
    return axios_http('post', '/experiment/check_id_pingzheng_exist', true, {
        id_pingzheng: id_pingzheng,
        id: id,
    })
    .then((res) => {
        if (res && res.data && res.data.result === "NO") {
            return false;
        }
        return true;
    });
};

export const check_valid_phone_mobile_tw = (input) => {
    if (input.indexOf('09') !== 0 || input.length !== 10) {
        return false;
    }
    return true;
};

// this checks for both TW ID and ARC new/old ID
export const check_valid_id_national_tw = (input) => {
    // total length is 10 ie. A123456789
    if (input.length === 10) {
        let arr = input.split('');
        // first char is a letter
        if (isNaN(arr[0])) {
            // last 9 chars is a number
            if (!isNaN(input.slice(-9))) {
                return true;
            }
            // the case for old ARC where first 2 chars are letters
            else if (isNaN(arr[1])) {
                // last 8 chars is a number
                if (!isNaN(input.slice(-8))) {
                    return true;
                }
            }
        }
    }
    return false;
};

export const strip_non_number = (input_string) => {
    let arr = input_string.split('');
    for (let a = 0; a < arr.length; a++) {
        if (isNaN(arr[a])) {
            arr.splice(a--, 1);
        }
    }
    return arr.join('');
};

export const clearSelectOptions = (id_select) => {
    for (let a = document.getElementById(id_select).options.length - 1; a >= 0; a--) {
        document.getElementById(id_select).remove(a);
    }
};

export const check_admission_query = () => {
    let token = localStorage.getItem('token');
    if (token) {
        let token_decoded = jwtDecode(token);
        if (token_decoded.admission_query_time_expiry) {
            if (new Date(token_decoded.admission_query_time_expiry) < new Date()) {
                return false;
            }
        }
        if (token_decoded.is_staff || token_decoded.is_student) {
            return false;
        }
        if (token_decoded.can_admission_query) {
            return true;
        }
    }
    return true;
};

export const check_login = () => {
    let token = localStorage.getItem('token');
    if (!token) {
        return false;
    }
    if (token) {
        let token_decoded = jwtDecode(token);
        if (token_decoded.time_expiry) {
            if (new Date(token_decoded.time_expiry) < new Date()) {
                return false;
            }
        }
        if (!token_decoded.is_staff && !token_decoded.is_student) {
            return false;
        }
    }
    return true;
};

export const axios_http = async (method, url, tokenAuth, data, responseType = false) => {
    let headers = {};
    headers['Content-Type'] = 'application/json';
    if (tokenAuth) {
        headers['Authorization'] = 'Bearer ' + localStorage.getItem('token');
    }

    let axios_obj = {
        method: method,
        url: urlHostRest + url,
        headers: headers,
        data: data,
    };
    if (responseType) {
        axios_obj['responseType'] = responseType;
    }
    return axios(axios_obj)
    .then(res => {
        return res;
    })
    .catch(err => {
        console.log(err.response);
        try {
            if (err.response.data.message === 'USER_NOT_FOUND') {
                alert('查無使用者');
                return;
            }
            if (err.response.data.message === 'NOT_FOUND') {
                alert('輸入帳號/密碼 錯誤');
                return;
            }
            if (err.response.data.message === 'ID_NATIONAL_NOT_FOUND') {
                alert('輸入帳號 錯誤');
                return;
            }
            if (err.response.data.message === 'ACCOUNT_IS_LOCKED') {
                alert('輸入帳號 已凍結');
                return;
            }
            if (err.response.data.message === 'ACCOUNT_NOT_ACTIVE') {
                alert('輸入帳號 已關閉');
                return;
            }
            if (err.response.data.message === 'ACCOUNT_NOT_STAFF') {
                alert('輸入帳號 非老師帳號');
                return;
            }
            if (err.response.data.message === 'ACCOUNT_NOT_STUDENT') {
                alert('輸入帳號 非學生帳號');
                return;
            }
            if (err.response.data.message === 'PASSWORD_INCORRECT') {
                alert('輸入密碼 錯誤');
                return;
            }
            if (err.response.data.message === 'PASSWORD_NOT_SET') {
                alert('密碼尚未設定，請重設密碼');
                return;
            }
            // alert('登入時效過期，請重新登入');
            // window.location.href = '/logout';
        }
        catch {
            // alert('發生錯誤，請重新登入');
            if (environment !== 'dev') {
                window.location.href = '/logout';
            }
        }
    });    
};

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties,
    };
};

export const numberWithCommas = (x) => {
    let parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}