import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../helpers/base';

const initialState = {
    reimbursement_plan_list_page: 0,
    reimbursement_plan_list_rows_per_page: 20,
    reimbursement_plan_list_query: '',
};

const set_reimbursement_plan_list_page = (state, action) => {
    return updateObject(state, {
        reimbursement_plan_list_page: action.data,
    });
};

const set_reimbursement_plan_list_rows_per_page = (state, action) => {
    return updateObject(state, {
        reimbursement_plan_list_rows_per_page: action.data,
    });
};

const set_reimbursement_plan_list_query = (state, action) => {
    return updateObject(state, {
        reimbursement_plan_list_query: action.data,
    });
};

// ==========

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_REIMBURSEMENT_PLAN_LIST_PAGE: return set_reimbursement_plan_list_page(state, action);
        case actionTypes.SET_REIMBURSEMENT_PLAN_LIST_ROWS_PER_PAGE: return set_reimbursement_plan_list_rows_per_page(state, action);
        case actionTypes.SET_REIMBURSEMENT_PLAN_LIST_QUERY: return set_reimbursement_plan_list_query(state, action);

        default: return state;
    };
};

export default reducer;