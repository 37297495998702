import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../helpers/base';

const initialState = {
    reimbursement_proof_approve_page: 0,
    reimbursement_proof_approve_rows_per_page: 50,
    reimbursement_proof_approve_query: '',
    reimbursement_proof_mine_page: 0,
    reimbursement_proof_mine_rows_per_page: 50,
    reimbursement_proof_mine_query: '',
};

const set_reimbursement_proof_approve_page = (state, action) => {
    return updateObject(state, {
        reimbursement_proof_approve_page: action.data,
    });
};

const set_reimbursement_proof_approve_rows_per_page = (state, action) => {
    return updateObject(state, {
        reimbursement_proof_approve_rows_per_page: action.data,
    });
};

const set_reimbursement_proof_approve_query = (state, action) => {
    return updateObject(state, {
        reimbursement_proof_approve_query: action.data,
    });
};

const set_reimbursement_proof_mine_page = (state, action) => {
    return updateObject(state, {
        reimbursement_proof_mine_page: action.data,
    });
};

const set_reimbursement_proof_mine_rows_per_page = (state, action) => {
    return updateObject(state, {
        reimbursement_proof_mine_rows_per_page: action.data,
    });
};

const set_reimbursement_proof_mine_query = (state, action) => {
    return updateObject(state, {
        reimbursement_proof_mine_query: action.data,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_REIMBURSEMENT_PROOF_APPROVE_PAGE: return set_reimbursement_proof_approve_page(state, action);
        case actionTypes.SET_REIMBURSEMENT_PROOF_APPROVE_ROWS_PER_PAGE: return set_reimbursement_proof_approve_rows_per_page(state, action);
        case actionTypes.SET_REIMBURSEMENT_PROOF_APPROVE_QUERY: return set_reimbursement_proof_approve_query(state, action);
        case actionTypes.SET_REIMBURSEMENT_PROOF_MINE_PAGE: return set_reimbursement_proof_mine_page(state, action);
        case actionTypes.SET_REIMBURSEMENT_PROOF_MINE_ROWS_PER_PAGE: return set_reimbursement_proof_mine_rows_per_page(state, action);
        case actionTypes.SET_REIMBURSEMENT_PROOF_MINE_QUERY: return set_reimbursement_proof_mine_query(state, action);
        default: return state;
    };
};

export default reducer;